// @flow
import React, { Component } from 'react';
import { baseMenuItemWidth, letterWidth, logoWidth } from 'constants/menuConstants';
import { dynamicClassName } from 'utils/dynamicClassName';
import { convertEncodedHTML } from 'utils/convertUtils';
import { Link } from 'react-router-dom';

type Props = {
  url: string,
  urlType: string,
  title: string,
  titleAll?: string,
  children?: React$Element<*>,
  isMobile: boolean,
  isActive?: boolean,
  isWideScreen?: boolean,
  col?: boolean,
  id: number,
  activateMenu?: Function,
  isDisplayed: (id: number) => boolean,
  onCloseMenu?: Function,
  menuX: number
};

type ComponentState = {
  isOpen: boolean,
  dropdownTargetX: number,
  updated: boolean
};

class MenuElement extends Component<Props, ComponentState> {
  _container: ?HTMLElement;
  _dropdownmenu: ?HTMLElement;
  _title: ?HTMLElement;
  state: ComponentState = {
    isOpen: false,
    dropdownTargetX: 0,
    updated: false
  };

  updateDimensions() {
    const { isWideScreen, menuX } = this.props;
    const { dropdownTargetX: dropdownTargetState } = this.state;
    const dropdownmenu = this._dropdownmenu;
    const title = this._title;
    if (dropdownmenu && title) {
      const titleBounds = title.getBoundingClientRect();
      const titleCenterX = titleBounds.left + title.offsetWidth;
      const extraMargin = isWideScreen ? 30 : 0;
      const dropdownTargetX =
        Math.round(titleCenterX - dropdownmenu.offsetWidth / 2 + logoWidth - menuX) + extraMargin;
      let diff = 0;
      if (dropdownTargetX > dropdownTargetState) {
        diff = dropdownTargetX - dropdownTargetState;
      } else if (dropdownTargetX < dropdownTargetState) {
        diff = dropdownTargetState - dropdownTargetX;
      }
      if (diff > 2) {
        this.setState({ dropdownTargetX });
      }
    }
  }

  componentDidMount() {
    this.updateDimensions();
  }

  componentDidUpdate() {
    this.updateDimensions();
  }

  toggleIsOpen = (e: MouseEvent) => {
    if (this.props.isMobile && this.props.children) {
      e.preventDefault();
      this.setState({ isOpen: !this.state.isOpen });
    } else if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
    if (this.props.activateMenu) {
      this.props.activateMenu(this.props.id);
    }
  };

  getSize = () => {
    return baseMenuItemWidth + letterWidth * this.props.title.length;
  };

  renderDropdown = (
    children: any,
    classNamesChildrenContainer: any,
    classNamesChildrenList: any,
    titleAll?: string,
    url?: string
  ) => {
    if (children){
      const { onCloseMenu } = this.props;
      return (
        <div
          className={classNamesChildrenContainer.build()}
          ref={c => (this._dropdownmenu = c)}>
          <ul style={{ left: this.state.dropdownTargetX }} className={classNamesChildrenList.build()}>
            <li className="menu__link-all">
              <Link to={url? url : '#'} onClick={onCloseMenu && onCloseMenu}>{convertEncodedHTML(titleAll)}</Link>
            </li>
            {children}
          </ul>
        </div>
      );
    }
    return null;
  };

  render() {
    const { url, urlType, title, titleAll, children, isMobile, col, isDisplayed, id, isActive } = this.props;
    const { isOpen } = this.state;
    const classNamesTitle = dynamicClassName('');
    children && classNamesTitle.add('accordion-trigger');
    isMobile && isOpen && classNamesTitle.add('is-selected');
    const classNameLi = dynamicClassName('');
    isActive && classNameLi.add('is-active') && classNamesTitle.add('is-active');

    const classNamesChildrenContainer = dynamicClassName('');
    col && classNamesChildrenContainer.add('menu__sub__selection');

    const classNamesChildrenList = dynamicClassName('menu__sub accordion-panel');
    col && classNamesChildrenList.add('menu__col');
    !isOpen && isMobile && classNamesChildrenList.add('is-hidden');

    if (isDisplayed(id)) {
      return (
        <li className={classNameLi.build()} ref={c => (this._container = c)}>
          { urlType !== 'external' &&
          <Link
            innerRef={c => (this._title = c)}
            to={url}
            className={classNamesTitle.build()}
            onClick={this.toggleIsOpen}
          >
            {convertEncodedHTML(title)}
          </Link>
          }
          { urlType === 'external' &&
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className={classNamesTitle.build()}
            >
            {convertEncodedHTML(title)}
            </a>
          }
          {children &&
            this.renderDropdown(
              children,
              classNamesChildrenContainer,
              classNamesChildrenList,
              titleAll,
              url)}
        </li>
      );
    }

    return null;
  }
}

export default MenuElement;
