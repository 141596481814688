// @flow
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';
import type { BlocContent } from 'types/NewsletterBloc';

export type MatchType = {
  adversaire: string,
  embleme_france: string,
  embleme_adversaire: string,
  france_drapeau: string,
  adversaire_drapeau: string,
  broadcaster_logo: string,
  reception: boolean,
  competition: string,
  journee: string,
  date_rencontre: string,
  lieu: string,
  france_score: number,
  adversaire_score: number,
  link: string,
  billetterie: string,
  adversaire_logo?: string,
  france_logo?: string,
  rencontre_terminee: boolean,
  rencontre_live: boolean,
  competition_internationale_link: string,
  competition_internationale_link_is_blank?: boolean,
  billetterie: string
};

export type CompetitionType = SEOMetaType & {
  id: number,
  slug: string,
  title: string,
  content: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  content_matchs_resultats: {
    data: Array<BlocContent>
  },
  content_news_tab: {
    data: Array<BlocContent>
  },
  content_classement_competition: {
    navigation: {label: string, typeClassement: string}[],
    bottom_page:{
      data: Array<BlocContent>
    },
    phase_de_poule: {
      data: Array<BlocContent>
    },
    phase_finale: {
      data: Array<BlocContent>
    }
  },
  content_group_composition_page_bottom: {
    data: Array<BlocContent>
  },
  featured_media: number,
  meta: {
    annee: number,
    pays_organisateur: string,
    type_competition: string,
    statut_competition: string,
    equipeId: number,
    equipeNom: string,
    site_officiel: string
  },
  meta_title: string,
  saison: Array<number>,
  link: string,
  dernieres_rencontres: Array<MatchType>,
  prochaines_rencontres: Array<MatchType>
};

export type RawCompetitionType = {
  id: number,
  date: string,
  modified: string,
  slug: string,
  status: string,
  type: string,
  link: string,
  title: {
    rendered: string,
    raw: string,
    data: [
      {
        type: string,
        text: string
      }
    ]
  },
  content: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  content_matchs_resultats: {
    data: Array<BlocContent>
  },
  content_news_tab: {
    data: Array<BlocContent>
  },
  content_classement_competition: {
    navigation: {label: string, typeClassement: string}[],
    bottom_page:{
      data: Array<BlocContent>
    },
    phase_de_poule: {
      data: Array<BlocContent>
    },
    phase_finale: {
      data: Array<BlocContent>
    }
  },
  content_group_composition_page_bottom: {
    data: Array<BlocContent>
  },
  featured_media: number,
  template: string,
  meta: {
    annee: number,
    pays_organisateur: string,
    type_competition: string,
    statut_competition: string,
    equipeId: number,
    equipeNom: string,
    site_officiel: string
  },
  saison: Array<number>,
  sidebar_id: number,
  excerpt: {
    raw: string
  },
  meta_title: string,
  tags_list: Array<any>,
  sidebar_content: {
    rendered: string,
    raw: string,
    data: Array<any>
  },
  dernieres_rencontres: Array<MatchType>,
  prochaines_rencontres: Array<MatchType>,
  niveau: number
} & SEOMetaType;

export const nullCompetition: CompetitionType = {
  ...nullSeoMeta,
  id: 0,
  slug: '',
  title: '',
  content: {
    rendered: '',
    protected: false,
    raw: '',
    data: []
  },
  content_matchs_resultats: {
    data: []
  },
  content_news_tab: {
    data: []
  },
  content_classement_competition: {
    navigation: [],
    bottom_page:{
      data: []
    },
    phase_de_poule: {
      data: []
    },
    phase_finale: {
      data: []
    }
  },
  content_group_composition_page_bottom: {
    data: []
  },
  featured_media: 0,
  meta: {
    annee: 0,
    pays_organisateur: '',
    type_competition: '',
    statut_competition: '',
    equipeId: 0,
    equipeNom: '',
    site_officiel: ''
  },
  saison: [],
  dernieres_rencontres: [],
  prochaines_rencontres: [],
  meta_title: '',
  link: ''
};
