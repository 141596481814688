// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import type { LogeRencontreType } from 'types/LogeList';

export type LogeDetailType = {
  title: string,
  id: number,
  rencontres: Array<LogeRencontreType>,
  description: string,
  content: {
    data: Array<BlocContent>,
  },
  order: number,
  prix_base: number,
  meta_title: string,
  meta_description: string,
  slug: string,
  link: string,
  nombreDePlace: number,
  exclusivite: string,
  prix: number,
  featuredVideo?: Object,
  featured_video: {
    data: Array<BlocContent>,
  },
  repas: string,
  cadeau: string,
  placeDeParking: string,
  featured_media: {
    src: string,
  },
  liste_enfants: Array<Object>,
  tags_list: Array<{
    id: number,
    name: string,
    image: {
      src: string,
    },
  }>,
  venue: string,
  position: string,
};

export type RawLogeDetailType = SEOMetaType & {
  id: string,
  liste_enfants: Array<Object>,
  rencontres: Array<LogeRencontreType>,
  title: {
    raw: string,
  },
  excerpt: {
    raw: string,
  },
  menu_order: number,
  content: {
    data: Array<BlocContent>
  },
  slug: string,
  link: string,
  meta_title: string,
  meta_description: string,
  featured_media: {
    src: string,
    legend: string
  },
  featured_video: {
    data: Array<BlocContent>,
  },
  meta: {
    featuredVideo?: Object,
    nombreDePlace: number,
    exclusivite: string,
    repas: string,
    cadeau: string,
    placeDeParking: string,
    prix: number,
    prix_base: number,
    venue: string,
    position: string,
  },
  tags_list: Array<{
    id: number,
    name: string,
    image: {
      src: string,
    },
  }>,
};

export const nullLogeDetail: LogeDetailType = {
  title: '',
  id: 0,
  description: '',
  liste_enfants: [],
  content: {
    data: [
      {
        tagName: 'p',
        type: 'element',
        attributes: [],
        children: [
          {
            type: 'text',
            text: 'Chargement du contenu de la loge...'
          }
        ]
      }
    ]
  },
  order: 0,
  slug: '',
  link: '',
  meta_title: '',
  meta_description: '',
  nombreDePlace: 0,
  featured_video: {
    data: [
      {
        tagName: 'p',
        type: 'element',
        attributes: [],
        children: [
          {
            type: 'text',
            text: 'Chargement du contenu de la video...'
          }
        ]
      }
    ],
  },
  prix: 0,
  prix_base: 0,
  exclusivite: '',
  repas: '',
  cadeau: '',
  featuredVideo: {},
  placeDeParking: '',
  venue: '',
  position: '',
  rencontres: [{
    id: 0,
    adversaire: '',
    reception: false,
    france_logo: '',
    adversaire_logo: '',
    equipe: '',
    date_rencontre: '',
    link: '',
  }],
  featured_media: {
    src: '',
  },
  tags_list: [{
    id: 0,
    name: '',
    image: {
      src: '',
    },
  }],
};
