// @flow

import { mergeIntoDefault } from 'utils/objectUtils';

import type { CompetitionInternationale, RawCompetitionInternationale } from 'types/competitionsInternationales';

export const nullCompetItl = {
  id: 0,
  date: '',
  modified: '',
  slug: '',
  status: '',
  type: '',
  link: '/',
  title: {
    rendered: '',
    raw: '',
    data: []
  },
  content: {
    rendered: '',
    data: []
  },
  featured_media: {
    id: 0,
    src: '',
    legend: '',
    alt: '',
    width: 0,
    height: 0,
    sizes: {}
  },
  menu_order: 0,
  template: '',
  meta: {
    inline_featured_image: false,
    annee: '',
    pays_organisateur: '',
    type_competition: '',
    statut_competition: '',
    equipeId: 0,
    equipeNom: '',
    site_officiel: '',
    start_date: '',
    end_date: '',
    current_competition: false,
    news_page_title: '',
    news_page_tag: '',
    competition_layout_type: ''
  },
  saison_7: [],
  saison_u20: [],
  saison: [],
  sidebar_id: 0,
  excerpt: { raw: '' },
  redirect: { type: 0, postid: '', title: '', url: '' },
  meta_title: '',
  meta_description: '',
  meta_noindex: false,
  tags_list: [],
  sidebar_content: {
    rendered: '',
    raw: '',
    data: []
  },
  niveau: 5,
  dernieres_rencontres: [],
  prochaines_rencontres: [],
  countdown_start_date: [],
  intro_page_evenementielle: {
    title: '',
    description: '',
    imageSource: ''
  },
  content_matchs_resultats: {
    data: []
  },
  content_news_tab: {
    data: []
  },
  content_classement_competition: {
    bottom_page: {
      data: []
    },
    navigation: [],
    phase_de_poule: {
      data: []
    },
    phase_finale: {
      data: []
    }
  },
  content_group_composition_page_bottom: {
    data: []
  },
  group_composition: {
    Pilier: [],
    Talonneur: [],
    'Deuxi\u00e8me ligne': [],
    'Troisi\u00e8me ligne Aile': [],
    'Troisi\u00e8me ligne Centre': [],
    'Demi de m\u00eal\u00e9e': [],
    "Demi d'ouverture": [],
    Centre: [],
    Ailier: [],
    Arrière: [],
    Staff: []
  },
  content_competition_over: {
    top_page: {
      data: []
    },
    competition_stats: {
      victoires: 0,
      transformations: 0,
      essais_marques: 0,
      defaites: 0,
      penalites: 0,
      essais_ensaisses: 0
    },
    players_stats: [],
    bottom_page: {
      data: []
    }
  }
};

export function convertFromApi(rawCompetition: RawCompetitionInternationale): CompetitionInternationale {
  if (!rawCompetition) {
    return nullCompetItl;
  }

  if (Array.isArray(rawCompetition)) {
    if (!rawCompetition[0]) {
      return nullCompetItl;
    }

    return mergeIntoDefault(nullCompetItl, rawCompetition[0]);
  }

  return mergeIntoDefault(nullCompetItl, rawCompetition);
}
