// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import { FETCH_SEARCH_SUCCESS, FETCH_SEARCH_FAILURE, FETCH_SEARCH_START } from 'actions/searchActions';

import type { ActionType } from 'types/Actions';
import type { ArticleType } from 'types/Article';
import type { PageListType } from 'types/PageList';
import type { EventType } from 'types/Event';
import type { JoueurType } from 'types/Joueur';
import type { QuestionType } from 'types/Faq';
import type { Status } from 'types/Status';

export type State = {
  articles: ArticleType[],
  events: EventType[],
  input: string,
  joueurs: JoueurType[],
  pages: PageListType[],
  pratiques_ok: string[],
  questions: QuestionType[],
  status: Status,
};

const initialState: State = {
  articles: [],
  events: [],
  input: '',
  joueurs: [],
  pages: [],
  pratiques_ok: [
      'Beach',
      'Ecole de Rugby',
      'Rugby à 5',
      'Rugby Féminin',
      'Compétition à 7',
      'Compétition à X',
      'Compétition à XV',
      'Compétition à XV : Féminin +18 ans',
      'Compétition à XV : Féminin -18 ans',
      'Rugby Loisir',
      'Compétition à X : Masculin -19 ans',
      'Compétition à X : Masculin -16 ans',
      'Compétition à X : Féminin +18 ans',
      'Compétition à X : Féminin -18 ans',
      'Compétition à X : Féminin -15 ans',
    ],
  questions: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_SEARCH_START:
      return {
        ...state,
        input: action.payload.input,
        status: STATUS_LOADING
      };
    case FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        articles: action.payload.search.articles,
        events: action.payload.search.events,
        joueurs: action.payload.search.joueurs,
        pages: action.payload.search.pages,
        questions: action.payload.search.questions,
        status: STATUS_SUCCESS
      };
    case FETCH_SEARCH_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
