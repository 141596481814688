// @flow

import React, { PureComponent } from 'react';

import Loading from 'components/fragments/Loading';
import ContentBloc from 'components/newsletter/blocs/ContentBloc';
import Switcher from 'components/profile/DashboardHome/Switcher';
import EmptyStateLicence from 'containers/profile/dashboardHome/EmptyStateLicenceContainer';
import MyFavoriteClubs from 'containers/profile/dashboardHome/MyFavoriteClubsContainer';
import { STATUS_SUCCESS, STATUS_LOADING } from 'constants/statusConstants';

import FidInscription from 'containers/profile/Fidelite/fidInscription';

import type { ArticleType, CategoryArticleType } from 'types/Article';
import type { PrivateAccountDashboardType } from 'types/PrivateAccountDashboardType';
import type { RouterProps } from 'types/Router';
import type { Status } from 'types/Status';
import type { FullUserType } from 'types/User';

export type StateProps = {
  categoriesArticle: CategoryArticleType[],
  categoriesArticleStatus: Status,
  licence: string,
  dashboard: PrivateAccountDashboardType[],
  dashboardStatus: Status,
  status: Status,
  userPref: FullUserType
};

export type DispatchProps = {
  fetchArticlesStaticCategories(): void,
  fetchPrivateAccountDashboard(): void,
  postUserPref: (action: string, value: any, token: string) => void
};

type State = {
  currentDashboard: PrivateAccountDashboardType | null,
  selectedArticleCategory: string
};

type Props = {
  currentUserProfile: string,
  setCurrentUserProfile(value: string): void
} & StateProps &
  RouterProps &
  DispatchProps;
// @TODO 1 : Add -> const { licence } = this.props;
// @TODO 2 : Replace false by licence === ''

export const ADMIN_USERS = [
  'ann-sophie@apsulis.io',
  'benjamin@apsulis.io',
  'justine.marcel@ffr.fr',
  'nathalie.roualec@ffr.fr',
  'quentin@apsulis.io',
  'valentin.boucher@ffr.fr'
];

export default class DashboardHome extends PureComponent<Props, State> {
  state: State = {
    currentDashboard: null,
    selectedArticleCategory: ''
  };

  componentDidMount() {
    const { fetchArticlesStaticCategories, fetchPrivateAccountDashboard, currentUserProfile, dashboard } = this.props;

    fetchPrivateAccountDashboard();
    fetchArticlesStaticCategories();

    if (dashboard.length > 0) {
      let currentDashboard = this.props.dashboard.find(item => item.user_profile === currentUserProfile);
      if (!currentDashboard) {
        currentDashboard = this.props.dashboard.find(item => item.user_profile === 'defaut');
      }
      this.setState({ currentDashboard });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      (prevProps.dashboard.length === 0 && this.props.dashboard.length > 0) ||
      this.props.currentUserProfile !== prevProps.currentUserProfile
    ) {
      let currentDashboard = this.props.dashboard.find(item => item.user_profile === this.props.currentUserProfile);
      if (!currentDashboard) {
        currentDashboard = this.props.dashboard.find(item => item.user_profile === 'defaut');
      }
      this.setState({ currentDashboard });
    }
  }

  /**
   * Executed in childcomponent Props MyNewsletterFeed
   *
   * @return {array} list of the last 5 articles from user's newsletters selection
   */
  getNews = (selectedArticleCategory: string): ArticleType[] => {
    const {
      categoriesArticle,
      userPref: { main_news, newsletter_themes }
    } = this.props;

    let filteredNews = [];

    const newsletter_themes_ids = Object.keys(newsletter_themes);
    //* get the news - all news if no newsletter
    if (categoriesArticle && Array.isArray(categoriesArticle) && categoriesArticle.length > 0) {
      // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Array/reduce
      filteredNews = categoriesArticle.reduce((filteredNews, categoryArticles) => {
        //* no subscription
        if (newsletter_themes_ids.length === 0) {
          filteredNews = categoryArticles.items;
        }

        //* if our user has newsletter subscription only keep the items from categories in which our user is registered
        if (newsletter_themes_ids.length > 0) {
          // we keep news as it is if the id of categoryArticles is not in the user selection
          // or is not in the user category selection (dropdown)
          if (!selectedArticleCategory.includes(categoryArticles.id.toString())) {
            return filteredNews;
          }
        }

        //* duplicate free article
        // for each article in category articles only keep it if every article in filtered news has a different id than the current article
        let newNews = categoryArticles.items.filter(item => filteredNews.every(news => news.id !== item.id));

        //* don't display the main news into the component
        newNews = newNews.filter(news => news.id !== main_news.id);

        return filteredNews.concat(newNews);
      }, []);
    }

    // sort by date
    // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Array/sort
    const sortedArticles = filteredNews.sort((a, b) => {
      const aDate = a.date.toString();
      const bDate = b.date.toString();

      return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
    });

    // return the items
    return sortedArticles;
  };

  handleDropdownCategory = (value: any) => {
    this.setState({
      selectedArticleCategory: value
    });
  };

  addMyNewsletterFeedProps = (blocFigure: any): any => {
    let props = { ...blocFigure };
    const {
      categoriesArticleStatus,
      userPref: { newsletter_themes }
    } = this.props;
    let { selectedArticleCategory } = this.state;
    selectedArticleCategory = selectedArticleCategory || Object.keys(newsletter_themes).join(',');
    const articles = this.getNews(selectedArticleCategory).slice(0, 5);

    props.articles = [...articles];
    props.handleDropdownCategory = this.handleDropdownCategory;
    props.displayMainNews = true;
    props.loading = categoriesArticleStatus === STATUS_LOADING;
    props.selectedArticleCategory = selectedArticleCategory;
    props.show_link_preferences = true;

    return props;
  };

  addClubFinderHubProps = (blocFigure: any): any => {
    let props = { ...blocFigure };
    props.private_account = true;

    return props;
  };

  renderContentBloc = () => {
    const { currentDashboard } = this.state;

    if (!currentDashboard || !currentDashboard.content) {
      return null;
    }

    return currentDashboard.content.map<any>((blocFigure, index) => {
      let props = { ...blocFigure };
      if (blocFigure.tagName === 'my-newsletter-feed') {
        props = this.addMyNewsletterFeedProps(blocFigure);
      } else if (blocFigure.tagName === 'club-finder-hub') {
        props = this.addClubFinderHubProps(blocFigure);
      }

      return <ContentBloc key={index} {...props} currentUserProfile={this.props.currentUserProfile} />;
    });
  };

  render() {
    const {
      currentUserProfile,
      dashboardStatus,
      status,
      userPref,
      userPref: {
        dashboard_profile_list,
        profil: { email }
      }
    } = this.props;
    const { currentDashboard } = this.state;

    // LOADING
    if (status !== STATUS_SUCCESS || dashboardStatus !== STATUS_SUCCESS) {
      return (
        <div className="col col-lg-8 mt-7 mb-8">
          <Loading />
        </div>
      );
    }

    // ??????
    if (false) {
      return (
        <div className="col col-lg-8">
          <EmptyStateLicence />
          <MyFavoriteClubs />
        </div>
      );
    }

    // RENDU
    return (
      <div className="col col-lg-8 mt-7 mb-8">
        <div className="espace-perso-content">
          {// Switch pour test des différents dashboard
          ADMIN_USERS.includes(email) && (
            <Switcher
              currentUserProfile={currentUserProfile}
              profile_list={dashboard_profile_list}
              setCurrentUserProfile={this.props.setCurrentUserProfile}
              title="Vous êtes en test sur les tableaux de bord en tant que : "
            />
          )}
          {userPref.email.includes('apsulis.io') && (
            <FidInscription classNamesAdded={'mb-7'} />
          )}
          {// L'affichage des différents dashboard
          currentDashboard && !!currentDashboard.content.length && this.renderContentBloc()}
        </div>
      </div>
    );
  }
}
