// @flow

export const options:{value: string, label: string}[] = [
  { value: 'aucun', label: '-'},
  { value: 'pilier', label: 'Pilier'},
  { value: 'talonneur', label: 'Talonneur'},
  { value: 'deuxieme-ligne', label: 'Deuxième ligne'},
  { value: 'troisieme-ligne', label: 'Troisième ligne'},
  { value: 'demi-de-melee', label: 'Demi de mêlée'},
  { value: 'demi-d-ouverture', label: 'Demi d’ouverture'},
  { value: 'trois-quarts-centre', label: 'Trois-quarts centre'},
  { value: 'trois-quarts-aile', label: 'Trois-quarts aile'},
  { value: 'arriere', label: 'Arrière'},
];
export const COMPETITION_BASE_URL:string = 'https://competitions.ffr.fr/api';
