// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import type { MenuElementType } from 'types/MenuElement';
import type { FullUserType } from 'types/User';
import { handleTagEvent } from 'utils/tagManagerUtils';

type Props = {
  subMenus: Array<MenuElementType>,
  title: string,
  userPref: FullUserType
};

type ComponentState = {
  isOpen: boolean
};

class TopBarDropdown extends PureComponent<Props, ComponentState> {
  _DropdownMenu: ?HTMLElement;
  state: ComponentState = {
    isOpen: false
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this._DropdownMenu && !this._DropdownMenu.contains((event.target: any))) {
      this.toggleIsOpen(event);
    }
  };

  toggleIsOpen = (e: MouseEvent) => {
    e.preventDefault();
    if (!this.state.isOpen) {
      document.addEventListener('mousedown', this.handleClickOutside, { once: true });
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  renderDropDownElements = () => {
    const { subMenus, userPref } = this.props;

    return subMenus.map<any>((subMenu, index) => {
      return (
        <li key={index}>
          <a
            href={subMenu.url}
            title={`Se rendre sur le site ${subMenu.title} (nouvel onglet)`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleTagEvent(
              'menu transverse',
              'clic lien sortant',
              subMenu.url,
              userPref,
              this.closeDropDown
            )}
          >
            {subMenu.title}
          </a>
        </li>
      );
    });
  };

  closeDropDown = () => {
    this.setState({
      isOpen: false
    });
  }

  render() {
    const { isOpen } = this.state;
    const { title } = this.props;
    const classNamesTopBarWrapper = dynamicClassName('topbar__sub accordion-panel');
    !isOpen && classNamesTopBarWrapper.add('is-hidden');

    return (
      <li ref={r => (this._DropdownMenu = r)}>
        <a
          className="accordion-trigger"
          onClick={this.toggleIsOpen}
          title="Afficher le menu"
          role='button'
          tabIndex='0'>
          {title}
        </a>
        <ul className={classNamesTopBarWrapper.build()} id="accordion-ligues">
          {this.renderDropDownElements()}
        </ul>
      </li>
    );
  }
}

export default TopBarDropdown;
